import React from 'react';
import { Typography } from '@mui/material';

type HeadingVariantsType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const Title = ({
  variant = 'h1',
  fontWeight,
  children,
  ...rest
}: React.PropsWithChildren<{ variant: HeadingVariantsType; fontWeight?: number }>): JSX.Element => {
  return (
    <Typography variant={variant} fontWeight={fontWeight} {...rest}>
      {children}
    </Typography>
  );
};

export default Title;
