import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Button, Typography } from '@mui/material';
import { GetServerSideProps, NextPage } from 'next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import api from 'app/api/client';
import PortalConfig from 'app/api/models/PortalConfig';
import Logo from 'app/components/common/Logo';
import LogoV2 from 'app/components/common/LogoV2';
import Panel from 'app/components/common/Panel';
import Title from 'app/components/common/Title';
import Globe from 'app/components/globe/globe';
import LandingFooter from 'app/components/layout/LandingFooter';
import { NextGetConfig } from 'app/types/nextjs';
import { Routes } from 'app/utils/constants';
import { useSnackbarContext } from 'app/utils/context/SnackbarProvider';
import { cookiesService } from 'app/utils/cookies';
import { errorHandler, LOGIN_ERRORS } from 'app/utils/errors';

const {
  publicRuntimeConfig: { IC_WD_DEV_IDP_ENDPOINT_API, IC_WD_OAUTH_API, AUTH_SERVICE },
} = getConfig() as NextGetConfig;

const LoginPage: NextPage = () => {
  const { t } = useTranslation('LoginPage');
  const router = useRouter();
  const { openSnackbar } = useSnackbarContext();

  useEffect(() => {
    if (router.query.error) {
      const actError = LOGIN_ERRORS.find(err => router.query.error_description === err.code);
      if (actError) openSnackbar(actError.message, 'error');
    }
  }, [router, openSnackbar]);

  const handleError = (err: Error) => errorHandler(err, openSnackbar);

  const handleRedirect = (responseUrl: string) => window.location.replace(decodeURIComponent(responseUrl));

  const onSignInWithPersonalClick = () => {
    api?.fetchAuthProviderRedirectUrl().then(handleRedirect).catch(handleError);
  };

  const onSignInWithSsoClick = () => router.push(Routes.auth.loginSso());

  const renderSsoButton = () => {
    if (AUTH_SERVICE === 'cognito') {
      return (
        <StyledSamlButton variant='outlined' onClick={onSignInWithSsoClick}>
          {t('loginWithSso')}
        </StyledSamlButton>
      );
    }
  };

  const getLoginButtonTitle = () => {
    if (AUTH_SERVICE === 'cognito') {
      return t('loginWithPersonal');
    }
    if (PortalConfig.isDCC()) {
      return t('loginToDCC');
    }
    return t('loginToPortal');
  };

  const renderLearnMoreLabel = () => {
    if (PortalConfig.isDefault() || PortalConfig.isDCC()) {
      return (
        <WebLink target='_blank' href={PortalConfig.website}>
          {t('learnMoreAt')} <ExitToAppIcon fontSize='small' />
        </WebLink>
      );
    }

    return null;
  };

  const renderDescription = () => {
    if (PortalConfig.isDCC()) {
      return t('descriptionDCC');
    }
    return t('description');
  };

  return (
    <>
      <PageWrapper>
        <ContentFooterWrapper>
          <ContentWrapper>
            <StyledPanel>
              {(PortalConfig.isDefault() || PortalConfig.isDCC()) && <StyledLogo />}
              <StyledTitle variant='h2'>{PortalConfig.title} Portal</StyledTitle>
              <StyledText variant='body1'>{PortalConfig.isDCC() ? t('welcomeDss') : t('welcome')}</StyledText>
              <Button color='primary' variant='contained' onClick={onSignInWithPersonalClick}>
                {getLoginButtonTitle()}
              </Button>
              {renderSsoButton()}
              <Links>
                <StyledLink href={`${IC_WD_OAUTH_API}/auth/register`}>{t('createFreeAccount')}</StyledLink>
                <StyledLink href={`${IC_WD_OAUTH_API}/auth/password/recovery`}>{t('resetMyPassword')}</StyledLink>
                <StyledLink href={`${IC_WD_DEV_IDP_ENDPOINT_API || IC_WD_OAUTH_API}/auth/resend_user_confirm`}>
                  {t('resendConfirmation')}
                </StyledLink>
              </Links>
            </StyledPanel>
            <MarketingColumn>
              <LogoV2 />
              <StyledTitleStrong variant='h2'>{PortalConfig.longTitle}</StyledTitleStrong>
              <StyledWhiteText variant='body1'>{renderDescription()}</StyledWhiteText>
              {renderLearnMoreLabel()}
            </MarketingColumn>
          </ContentWrapper>
          <StyledFooter />
        </ContentFooterWrapper>
      </PageWrapper>
      <Globe />
    </>
  );
};

export default LoginPage;

export const getServerSideProps: GetServerSideProps = ctx => {
  if (cookiesService.isLoggedIn(ctx.req.headers.cookie)) {
    return { redirect: { destination: Routes.gettingStarted.list().toString(), permanent: false } };
  }
  return {
    props: {},
  };
};

const PageWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row;
  max-width: 1280px;
  align-self: center;
  align-items: center;
  flex: 1;
`;

const ContentFooterWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100vh;
`;

const MarketingColumn = styled.div`
  margin-left: 80px;
  max-width: 500px;
  color: white;
`;

const StyledPanel = styled(Panel)`
  max-width: 350px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledLogo = styled(Logo)`
  width: 30px;
  margin-bottom: 16px !important;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 16px !important;
`;

const StyledTitleStrong = styled(StyledTitle)`
  font-weight: 500;
  font-size: 28px;
`;

const StyledText = styled(Typography)`
  margin-bottom: 24px !important;
`;

const StyledWhiteText = styled(Typography)`
  margin-bottom: 24px !important;
  color: white;
`;

const Links = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 24px;
  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

const WebLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 16px;
  color: inherit;
  svg {
    margin-left: 8px;
  }
`;

const StyledLink = styled.a`
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

const StyledFooter = styled(LandingFooter)`
  margin-bottom: 24px;
`;

const StyledSamlButton = styled(Button)`
  margin-top: 16px;
  white-space: nowrap;
`;
