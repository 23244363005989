import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import * as TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';

import setupCamera from 'app/components/globe/camera';
import drawDots from 'app/components/globe/dots';
import { drawLights } from 'app/components/globe/lights';
import { getImageData } from 'app/components/globe/utils';

export interface GlobePointCoords {
  latlng: LabelDataInterface;
  coords: THREE.Vector3;
}

export interface ArcDataInterface {
  start: GlobePointCoords;
  end: GlobePointCoords;
}

export interface LabelDataInterface {
  latitude: number;
  longitude: number;
  title?: string;
}

const FPS_CAP_30 = 1000 / 30;

const Globe: FC = () => {
  const ref = useRef<HTMLDivElement>();

  const [loading, setLoading] = useState<boolean>(true);
  const [size, setSize] = useState<number>(1);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    const scene = new THREE.Scene();
    if (ref.current && !loading) {
      renderer.setSize(window.innerWidth, window.innerHeight);
      ref.current.appendChild(renderer.domElement);

      const vFOV = 45 * (Math.PI / 180);
      const targetSize = window.innerHeight / (2 * Math.tan(vFOV / 2));

      setSize(targetSize * 0.69);

      const globe = new THREE.Mesh(
        new THREE.SphereBufferGeometry(1, 100, 100),
        new THREE.MeshStandardMaterial({ color: new THREE.Color('#253b49') }),
      );

      scene.add(globe);

      const camera = setupCamera();

      const cloud = new THREE.Object3D();

      const loader = new THREE.TextureLoader();

      loader.load('/static/images/globe/earthspec1k.gif', (tex: THREE.Texture) => {
        const imageData = getImageData(tex?.image as ImageData);

        if (imageData) {
          cloud.add(drawDots(imageData));
        }

        if (ref.current) {
          ref.current.style.opacity = '1';
        }
      });

      const lights = drawLights();

      scene.add(lights);

      scene.add(cloud);

      const animate = (time?: number) => {
        setTimeout(() => requestAnimationFrame(animate), FPS_CAP_30);
        cloud.rotation.y -= 0.001;
        TWEEN.update(time);
        renderer.render(scene, camera);
      };

      animate();
    }
  }, [loading]);

  return (
    <GlobeWrapper>
      <StyledDiv ref={ref as MutableRefObject<HTMLDivElement>}>
        <Glow size={size} />
        <Atmo size={size} />
      </StyledDiv>
    </GlobeWrapper>
  );
};

export default Globe;

const GlobeWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  left: -35%;
  bottom: 0;
  z-index: 1;
`;

const StyledDiv = styled.div`
  transition: opacity 4s;
  opacity: 0;
  position: relative;
`;

const BaseEffect = styled.span<{ size: number }>`
  position: absolute;
  content: '';
  height: ${({ size }) => Math.round(size)}px;
  width: ${({ size }) => Math.round(size)}px;
  left: 50%;
  top: 50%;
  margin-left: ${({ size }) => -Math.round(size / 2)}px;
  margin-top: ${({ size }) => -Math.round(size / 2)}px;
  border-radius: 50%;
  z-index: -1;
`;

const Glow = styled(BaseEffect)`
  margin-left: ${({ size }) => -Math.round(size / 2) - 10}px;
  box-shadow: 0 0 35px #289ec6;
`;

const Atmo = styled(BaseEffect)`
  height: ${({ size }) => Math.round(size + 20)}px;
  width: ${({ size }) => Math.round(size + 20)}px;
  margin-left: ${({ size }) => -Math.round(size / 2) - 10}px;
  margin-top: ${({ size }) => -Math.round(size / 2) - 10}px;
  box-shadow: inset 0 0 35px #fff;
  opacity: 0.6;
  z-index: 1;
  mix-blend-mode: overlay;
`;
