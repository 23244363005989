import * as THREE from 'three';

import { getPixel, polar2canvas } from 'app/components/globe/utils';

export default function drawDots(
  maskImageData: ImageData,
): THREE.InstancedMesh<THREE.CircleGeometry, THREE.MeshPhongMaterial> {
  const DOT_COUNT = 60000;

  const vector = new THREE.Vector3();

  const dotGeometry = new THREE.CircleBufferGeometry(0.0035, 5);
  const dotMaterial = new THREE.MeshPhongMaterial({
    color: 0x5a8096,
    opacity: 0.5,
    transparent: true,
    side: THREE.BackSide,
  });

  const dotsMesh = new THREE.InstancedMesh(dotGeometry, dotMaterial, DOT_COUNT / 3);
  let j = 0;
  for (let i = DOT_COUNT; i >= 0; i -= 1) {
    const r = 1.001;
    const phi = Math.acos(-1 + (2 * i) / DOT_COUNT);
    const theta = Math.sqrt(DOT_COUNT * Math.PI) * phi;

    vector.setFromSphericalCoords(r, phi, theta);

    const polarCoords = { r, phi, theta: theta % (2 * Math.PI) };

    const samplePos = polar2canvas(polarCoords);
    samplePos.x *= maskImageData.width;
    samplePos.y *= maskImageData.height;

    const pixelData = getPixel(maskImageData, samplePos.x, samplePos.y);
    const transform = new THREE.Object3D();

    if (Object.values(pixelData).reduce((a: number, b: number) => a + b) <= 255 * 3) {
      transform.position.set(vector.x, vector.y, vector.z);
      transform.lookAt(new THREE.Vector3(0, 0, 0));
      transform.updateMatrix();
      dotsMesh.setMatrixAt(j, transform.matrix);
      j++;
    }
  }
  return dotsMesh;
}
